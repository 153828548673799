import bsc from "../assets/images/bsc.png"
import avax from "../assets/images/avalanche.png"
import arbitrum from "../assets/images/arb.png"
import eth from "../assets/images/eth.png"
import zkSync from "../assets/images/zkSync.png"
// export const RPC_URL = "https://bsc-dataseed1.binance.org/";
// export const CHAIN_ID = 56;

export const MAX_UNIT256 = '115792089237316195423570985008687907853269984665640564039457584007913129639935'

export const ALLOWED_PRICE_IMPACT_LOW = 1 //1%

export const ALLOWED_PRICE_IMPACT_MEDIUM = 3 //3%

export const ALLOWED_PRICE_IMPACT_HIGH = 5  //5%

export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN = 10 //10%

export const BLOCKED_PRICE_IMPACT_NON_EXPERT = 15 //15%

export const RPC_URL = "https://data-seed-prebsc-1-s1.binance.org:8545/";

export const CHAIN_ID = 97;

export const ONEDAYINSECONDS = 86400;

export const frontendurl = window.location.origin;

export const AdminSaleAddress = "0x518221c5013D745359A2B86863Ef21C75075C623" //"0x5cb3aDAE7fba7439727E62d4FA57d93D3d608084"  //"0x92eAEAbf96AaE481D01e1F5C5aE73C43ecEf645d" //0x22aD5493f105E9EE3fC03e35A87fD45EB65B64a3"

export const AdminTokenAddress = "0x16ff9EDC133af5fc4ff46bF15562A2F200952A42"   //"0xe43a3EA9a3e411E79Bc732d54D00B8a03DD4E79C" //"0x323655b1169D2f7131C6bd3A20b2F2922AFAf3Eb" //"0x0b754ba95eD079FacCAc71245280dd367e1d4134"

export const userFee = 5

export const userFeeWithtoken = 4

export const MAX_HOPS = 3

export const CHAINS = [
    // {
    //     NAME: "BNB Chain",
    //     RPC_URL:"https://bsc-dataseed.binance.org/",
    //     SYMBOL:"BNB",
    //     IMAGE:bsc,
    //        // RPC_URL:[
    //         //     "https://neat-fragrant-crater.bsc-testnet.quiknode.pro/b5baa8a7cdddef6b65cdb7ac9f8e598244df6e4f/",
    //         //     "https://bsc-testnet.publicnode.com",
    //         //     "https://data-seed-prebsc-1-s2.bnbchain.org:8545",
    //         //     "https://bsc-testnet.blockpi.network/v1/rpc/public"],
    //     CHAIN_ID:56,
    //     TRANSACTION : "https://bscscan.com/tx",
    //     TokenTracker : "https://bscscan.com/token/",
    //     Testnet : "https://bscscan.com/address",
    //     // MULTICALL: "0x232e410b1fa58fa37921ad03E5deC6a507cd5CDe",
    //     EndBlock : "https://bscscan.com/block/countdown"

    // },
    {
        NAME: "BNB Test Chain",
        RPC_URL: "https://bsc-testnet.blockpi.network/v1/rpc/public",
        SYMBOL: "BNB",
        IMAGE: bsc,
        // RPC_URL:[
        //     "https://neat-fragrant-crater.bsc-testnet.quiknode.pro/b5baa8a7cdddef6b65cdb7ac9f8e598244df6e4f/",
        //     "https://bsc-testnet.publicnode.com",
        //     "https://data-seed-prebsc-1-s2.bnbchain.org:8545",
        //     "https://bsc-testnet.blockpi.network/v1/rpc/public"],
        CHAIN_ID: 97,
        BNB: "0x0000000000000000000000000000000000000000",  //ruby
        USDT: "0x0A1F4c4E14Dd83018942c5813768A8bf70b78599", //ruby
        USDC: "0x0A1F4c4E14Dd83018942c5813768A8bf70b78599", //ruby
        DAI: "0x0A1F4c4E14Dd83018942c5813768A8bf70b78599", //ruby
        ROUTER: "0x097b0468D402B7a13969C3D8B145717a6ef928DD",
        LIQUIDITY_TOKEN_ADDRESS : "0xf8bD5BA5a8e9144C234d6D2Ee08988Eabcd39E17", 
        STANDARD_TOKEN_ADDRESS : "0x9eFcd26442241B3A243a7b4c5BE154a03ac25325", 
        LOCK_ADDRESS : '0x3e3188f74067E636799406565cDDf61fbf4E73B3',
        TRANSACTION: "https://testnet.bscscan.com/tx",
        TokenTracker: "https://testnet.bscscan.com/token/",
        Testnet: "https://testnet.bscscan.com/address",
        // MULTICALL: "0x232e410b1fa58fa37921ad03E5deC6a507cd5CDe",
        EndBlock: "https://testnet.bscscan.com/block/countdown"

    },
    // {
    //     NAME: "Avalanche",
    //     RPC_URL:"https://api.avax.network/ext/bc/C/rpc",
    //     CHAIN_ID:43114,
    //     SYMBOL:"AVAX",
    //     IMAGE: avax,
    //     TRANSACTION : "https://snowtrace.io/tx/",
    //     TokenTracker : "https://snowtrace.io/token/",
    //     Testnet : "https://snowtrace.io/address/",
    //     EndBlock : "https://snowtrace.io/block/countdown"
    // },
    // {
    //     NAME: "Ethereum",
    //     RPC_URL:"https://rpc2.sepolia.org",
    //     CHAIN_ID:11155111,
    //     IMAGE: eth,
    //     TRANSACTION : "https://sepolia.etherscan.io/tx/",
    //     TokenTracker : "https://sepolia.etherscan.io/token/",
    //     Testnet : "https://sepolia.etherscan.io/address/", 
    //     EndBlock : "https://sepolia.etherscan.io/block/countdown"

    // },
    {
        NAME: "Avalanche Test",
        RPC_URL: "https://api.avax-test.network/ext/bc/C/rpc",
        CHAIN_ID: 43113,
        SYMBOL: "AVAX",
        IMAGE: avax,
        AVAX: "0x0000000000000000000000000000000000000000",  //ruby
        // BUSD : "0xd7613f4F96d039f9F15B75D51232719D220B047e",
        USDT: "0x8d9f46183A23d81dcCc172C92bDb4553c1b67FFf",  //ruby
        USDC: "0xd7613f4F96d039f9F15B75D51232719D220B047e", //ruby
        DAI: "0x8d9f46183A23d81dcCc172C92bDb4553c1b67FFf",  //ruby
        LIQUIDITY_TOKEN_ADDRESS : "0x06Fb02c073Cda5B84D323C407437E05DA6388ca1", //ruby
        STANDARD_TOKEN_ADDRESS : "0x3cB251c826bD7b5f3617A0CEE364d8602B4EE58F",  //ruby
        TRANSACTION: "https://testnet.snowtrace.io/tx/",
        TokenTracker: "https://testnet.snowtrace.io/token/",
        Testnet: "https://testnet.snowtrace.io/address/",
        EndBlock: "https://testnet.snowtrace.io/block/countdown"
    },
    // {
    //     NAME: "Arbitrum",
    //     RPC_URL:"https://arb1.arbitrum.io/rpc",
    //     CHAIN_ID:421614,
    //     IMAGE: arbitrum,
    //     TRANSACTION : "https://arbiscan.io/tx/",
    //     TokenTracker : "https://arbiscan.io/token/", 
    //     Testnet : "https://arbiscan.io/address/",
    //     EndBlock : "https://arbiscan.io/block/countdown"
    // },
    // {
    //     NAME: "zkSync",
    //     RPC_URL:"https://testnet.era.zksync.dev",
    //     CHAIN_ID:280,
    //     IMAGE: zkSync,
    //     TRANSACTION : "https://explorer.zksync.io/tx/",
    //     TokenTracker : "https://explorer.zksync.io/token/",
    //     Testnet : "https://explorer.zksync.io/address/",
    //     EndBlock : "https://explorer.zksync.io/block/countdown"
    // },

];

//local
// export const FRONT_URL = "http://localhost:3000"
// export const API_URL = "http://localhost:7097"

//demo
export const FRONT_URL = "https://donutswapreact.maticz.in"
export const ANALYTICS_URL = "https://donutswap.maticz.in"
export const API_URL = "https://donutswap-backend.maticz.in/"

//staging
// export const FRONT_URL = "https://stage.donutswap.finance"
// export const ANALYTICS_URL = "https://stageanalytics.donutswap.finance"

//live
// export const FRONT_URL = "https://donutswap.finance"
// export const ANALYTICS_URL = "https://analytics.donutswap.finance"



export const FUND_RECEIVER = "0x12ABeD5514FD6F6716F1154a1Be05beD9E1a11dF"

export const COINS = ["BNB", "DAI", "USDT", "USDC", "BNB", "MATIC", "AVAX", "CRO", "FTM"];

export const HC = ["Presale", "Fairlaunch", "Auction", "Subscription"];

export const STATUS = ["Upcoming", "Live", "Ended"];

export const TOKEN_FEE = 0.01;

export const ZEROTH_ADDRESS = "0x0000000000000000000000000000000000000000"

export const REFERRER_ADDRESS = "0x754a9c737dF3224B0A200408120c07F74Cef112f"

// 1 day, 7 days, 30 days, 1 year, 5 years
export const DAYS_TO_CALCULATE_AGAINST = [1, 7, 30, 365, 1825]

export const MINIMUM_LIQUIDITY = 0.000000000000001

// export const WBNB_Address = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"; // BSC Mainet

// export const WBNB_Address = "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd";  //BSC Testnet

export const WBNB_Address = "0x1d308089a2d1ced3f1ce36b1fcaf815b07217be3"

export const PROJECT_ID = "b8a1daa2dd22335a2fe1d2e139980ae0"

export const BURN = "0x000000000000000000000000000000000000dEaD";

// export const BLOCKS_PER_YEAR = 10512000;

export const BSC_BLOCK_TIME = 3

export const BLOCKS_PER_YEAR = (60 / BSC_BLOCK_TIME) * 60 * 24 * 365 // 10512000

export const INTERVAL = 300; // 5 minutes
