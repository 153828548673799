
export const PROXY = {
	56: "", //bsc mainnet

	97: '0x9b1CB0dA9ac2Feb3107b9027897e311421736F9c',//'0xB9E67D7c3b6957CacF9D835691a45BF62260F7a5',//"0x8eb14f50074D487c62F646F53F629d092B25c0fC", // new bsc testnet

	137: "", //polygon mainnet

	1: "",

	3: "",

	43113: "", // avalanche testnet 

	42161: ""  //arbitrum
};

export const XLAUNCH = {
	56: "", //bsc mainnet

	97: '0x317a0AaaDc9c98684cD7e2F8dbA29594bf7C1367',//'0x03A8243291e5EaF4be46580Af3e7b625fC96300A',//'0xb4Eb2e12c13cC4f76DBF196Dee38e0C6C2F8D789',

	137: "", //polygon mainnet

	1: "",

	3: "",

	43113: "", // avalanche testnet 

	42161: ""  //arbitrum
};


export default {
	xtoken: {

		//bsc
		56: '0xc5c26A06FF435859d32c0D07c4418d8877b1E1dB', //M
		97: '0x846FE8E884B862F720Cc7728eE42f1a29300AB17',//'0x0D3B32a16dab18173696b6cbB267D6fc0018D3a8', //T

		//Eth
		1: '', //M
		11155111: '', //T

		//Avax
		43114: '0x296EFfbF5Cc1Dad8035302E54A6360C09896dc45', //M
		43113: '', //T

		//Arbitrum
		42161: '', //M
		421614: '', //T

		//zkSync
		324: '', //M
		280: '', //T

	}
}